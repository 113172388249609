import React, { useRef } from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import HomeHero from "../modules/hero/home-hero";
import Footer from "../components/footer/footer";

const Page = ({ location }) => {
  const pageLayoutRef = useRef();

  const seo = {
    pageUrl: "/",
    pageTitle: "Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "Vello | A full-service creative agency.",
  };

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <HomeHero pageLayoutRef={pageLayoutRef} />
        <Footer pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default Page;
