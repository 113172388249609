import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import AnimationLink from "../animation-link";
import CurveIcon from "../icons/curve-icon";

import Section from "../section";
import media from "../../styles/media";

const Footer = (props) => {
  const { pageLayoutRef } = props;

  const items = [
    {
      text: "Start a project",
      link: "/start-a-project",
    },
    {
      text: "Our Services",
      link: "/services",
    },
    {
      text: "Our Work",
      link: "/work",
    },
  ];
  return (
    <Section>
      <Container>
        <Menu>
          {items.map((item, index) => (
            <StyledItem key={index}>
              <AnimationLink navLink={item.link} pageLayoutRef={pageLayoutRef}>
                {item.text}
              </AnimationLink>
            </StyledItem>
          ))}
        </Menu>
        <IconWrapp>
          <CurveIcon />
        </IconWrapp>
      </Container>
    </Section>
  );
};

export default Footer;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.div`
  display: flex;
  div:first-child {
    padding-left: 0;
  }
  div:last-child {
    border-right: 0px;
  }
`;

const IconWrapp = styled.div`
  width: 62px;
  height: 39px;
  color: red;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #00d4c1 !important;
    }
  }
  ${media.tablet`
    width: 33px; height: 20px;
  `};
`;

const StyledItem = styled.div`
  padding-left: 35px;
  padding-right: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 91.6%;
  color: #000000;
  border-right: 1px solid #00d4c1;
  padding-top: 8px;
  a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      outline: 0;
      &:after {
        width: 100%;
      }
      & > * {
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
    }
    &:after {
      content: "";
      display: block;
      width: 0px;
      height: 8px;
      position: relative;
      bottom: 8px;
      background-color: #fb8fa4;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      opacity: 0.5;
    }
  }
  ${media.bigDesktop`
    font-size: 1rem;
    `};
  ${media.tablet`
    font-size: .875rem;
    padding: 6px 15px 0px 15px;
    `};
  ${media.phone`
    font-size: .75rem;
    padding: 6px 8px 0px 8px;
    `};
`;
